<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">ご契約プラン</h1>
        <banner-hint>
          ご契約のプランを変更したい場合はこちからプランの変更申請をしてください。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="px-10 pb-10 mb-5"
          elevation="1"
        >
          <v-card-title class="px-0">
            現在ご契約のプラン
          </v-card-title>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="shopData.shop_name"
                  label="店舗名"
                  filled readonly
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="plan.system_plan_name"
                  label="プラン名"
                  filled readonly
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3"
        class="d-flex justify-strech"
        v-for="systemPlan in systemPlans"
        :key="systemPlan.id"
      >
          <v-card
            class="d-flex flex-column pa-3"
            :class="plan.system_plan_id === systemPlan.id && 'current-plan elevation-3'"
            hover
          >
            <v-card-title>
              {{ systemPlan.plan_name }}
            </v-card-title>

            <v-card-text
              class="flex-shrink-0 flex-grow-1"
            >
              <pre>{{ systemPlan.description }}</pre>
            </v-card-text>

            <v-card-subtitle>
              <p>月額：{{ price(systemPlan.monthly_charge) }}</p>
              <!-- <p>初期費用：{{ price(systemPlan.initial_cost) }}</p> -->
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                :disabled="plan.system_plan_id === systemPlan.id"
                depressed
                color="primary"
                @click="applyUpgrade(systemPlan.id)"
              >プラン変更申請</v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <p class="text-caption text--secondary">*金額は全て税別です</p>
      </v-col>
    </v-row>

    <!-- プラン詳細リスト -->
    <v-row>
      <v-col cols="12">
        <v-card flat tile>
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  v-for="systemPlan in systemPlans"
                  :key="systemPlan.id"
                >
                  {{ systemPlan.plan_name }}
                </th>
              </tr>
            </thead>
            <tbody v-if="systemPlanPricings.length" >
              <tr
                v-for="itemPricing in systemPlanPricings"
                :key="itemPricing.id"
              >
                <td class="item text--primary">{{ itemPricing.item }}</td>
                <td>{{ itemPricing.plan_a }}</td>
                <td>{{ itemPricing.plan_b }}</td>
                <td>{{ itemPricing.plan_c }}</td>
                <td>{{ itemPricing.plan_d }}</td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </v-col>
    </v-row>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import { API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue"
// import IconInfo from "@/components/_IconInfo.vue"
import ModalConfirm from '@/components/_ModalConfirm.vue'

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    // 'icon-info': IconInfo,
    'modal-confirm': ModalConfirm,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      plan: {},
      systemPlans: [],
      systemPlanPricings: [],
      adPlans: [],
      loading: false,
      loadingMessage: '',
      modalMessage: '',
      valiRules: ValidationRules,
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(API_ENDPOINT + '/', this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    price() {
      return number => !number ? '無料' : (Number(number).toLocaleString() + '円')
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = 'データ取得中・・・'

      this.setSystemPlanPricings()

      await this.setShopCurrentPlan()
      await this.setSystemPlans()

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //店舗契約プラン取得
    setShopCurrentPlan() {
      return this.adminApi.getReqWithAuth('plan/').then( data => {
        if (!data) return
        this.plan = {...data}
      })
    },

    //システムプランマスター取得
    setSystemPlans() {
      return this.publicApi.getReqSystemPublic('system-plan/').then( results => {
        if (!results || !results.length) return
        this.systemPlans = [...results]

        this.systemPlans.map( plan => {
          if (plan.id === this.plan.system_plan_id) {
            this.plan.system_plan_name = plan.plan_name
            this.plan.system_plan_charge = plan.monthly_charge
            this.plan.initial_cost = plan.initial_cost
          }
        })
      })
    },

    //システムプラン詳細取得
    setSystemPlanPricings() {
      this.publicApi.getReqSystemPublic('system-plan-pricing/').then( results => {
        if (!results || !results.length) return
        this.systemPlanPricings = [...results]
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //プラン変更申請
    applyUpgrade(systemPlanId) {
      this.loading = true
      this.loadingMessage = '申請送信中・・・'

      const apiPath = 'update/plan/upgrade-application/'

      const updateData = { system_plan_id: systemPlanId }
      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('POST', apiPath, payload).then(() => {
        this.modalMessage = '<p class="mb-2">プラン変更の申請を承りました。</p>' +
          '<p>24時間以内に内容確認メールがアカウントIDのメールアドレスと店舗責任者様のメールアドレスに届くので忘れずにご確認ください。</p>'
        this.$refs.modalConfirm.open({})
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
>>> .v-card--hover {
  cursor: default;
}
.current-plan {
  border: thin solid var(--v-primary-base) !important;
}

pre {
  white-space: pre-wrap;
  font-size: 1.1em;
  line-height: 1.6em;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: solid 1px #e3e3e3;
}
thead tr {
  color: var(--v-accent-base);
  background: var(--v-primary-lighten1);
}
thead th:first-child {
  background-color: var(--content-bg-diff);
}
.theme--dark thead th:first-child {
  background-color: var(--content-bg-dark-diff);
}
tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}
.theme--dark tbody tr:nth-child(even) {
  background-color: #111;
}
th {
  font-size: 1em;
  height: 80px;
  padding: 0 10px;
  text-align: center;
  border: solid 1px #e3e3e3;
}
td {
  height: 70px;
  padding: 0 15px;
  text-align: center;
  color: #666;
  border: solid 1px #e3e3e3;
}
.theme--dark td, .theme--dark th {
  border: solid 1px #333;
}
td.item {
  font-size: .9em;
  font-weight: bold;
  text-align: left;
  color: var(--v-accent-base);
  background-color: var(--v-primary-lighten1);
}
</style>
