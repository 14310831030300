<template>
  <v-container fluid
    v-if="openModal"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openModal"
        max-width="400"
        persistent
      >
        <v-card class="px-7 pt-7 pb-5">
          <v-card-text class="pa-0 pb-3">
            <p>
              <slot></slot>
            </p>
          </v-card-text>
          <v-card-actions class="pa-0 pt-3 justify-end">
            <v-btn
              v-if="buttonLabel"
              :disabled="loading"
              depressed
              color="primary"
              @click="confirmed"
            >
              {{ buttonLabel }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="close"
            >
              閉じる
            </v-btn>
          </v-card-actions>

          <!-- ローダー -->
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute top
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      comeBack: {},
      yesCallback: null,
      noCallback: null,
      buttonLabel: '',
      openModal: false,
      loading: false,
    };
  },

  methods: {
    open(modalHanddown) {
      this.buttonLabel = modalHanddown.buttonLabel;
      this.yesCallback = modalHanddown.yesCallback;
      this.noCallback = modalHanddown.noCallback;
      this.comeBack = modalHanddown.comeBack || {};
      this.openModal = true;
    },

    close() {
      if (this.noCallback) {
        this.noCallback();
      }
      this.loading = false
      this.buttonLabel = null;
      this.yesCallback = null;
      this.noCallback = null;
      this.comeBack = null;
      this.openModal = false;
    },

    confirmed() {
      this.loading = true

      let response;
      if (this.yesCallback) {
        response = this.yesCallback(this.comeBack);
      }
      this.$emit("modal-confirmed", response);
    }
  }
};
</script>

<style scoped>
</style>
